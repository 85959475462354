import { userConfig, UserConfigs } from "../common/user-config";
import { login, logout } from "./auth";

const DEMO_USERNAME = "demo";
const DEMO_PASSWORD = "demodemo";

export const setupDemo = (isDemo) => {
    // buttons to disable
    document.querySelectorAll(".btn-not-demo").forEach((el) => {
        if (isDemo) {
            el.classList.add("disabled");
        } else {
            el.classList.remove("disabled");
        }
    });

    // text to show
    document.querySelectorAll(".info-for-demo").forEach((el) => {
        if (isDemo) {
            el.classList.remove("d-none");
        } else {
            el.classList.add("d-none");
        }
    });

    // link for logout
    document.querySelectorAll("a.link-for-exit-demo").forEach((link) => {
        link.addEventListener("click", (event) => {
            event.preventDefault();
            logout();
            window.location.replace("/index.html");
        });
    });
};

export const goToDemo = async (api) => {
    // get demo token
    const [status, _] = await login(api, DEMO_USERNAME, DEMO_PASSWORD);

    // setup some default config, if not already defined
    if (!userConfig.get(UserConfigs.selectedParamType)) {
        userConfig.set(UserConfigs.selectedParamType, "alkalinity");
    }

    if (status === 200) {
        window.location.replace("/summary.html");
        return true;
    }

    return false;
};
